import React from 'react';
import {Link} from 'gatsby';

import Page from 'src/components/functional/page';

import ContentTag from 'src/components/functional/content-tag';

import InformationDiagram from 'images/presentation/information.png';
import Architecture from 'images/presentation/architecture.png';
import Gannt from 'images/presentation/gannt.png';
import Phases from 'images/presentation/phases.png';

const PresentationPage = () => {
  return (
    <Page>
      <h3>Progress:</h3>
      <ul>
        <li>- 47% complete</li>
        <li>- 10,000 lines of code</li>
        <img src={Gannt} alt="Gannt" />
        <img src={Phases} alt="Phases" />
      </ul>
      <h3>Community - temp</h3>
      <Link to="/about/community" className="link-primary">
        Community - mockup
      </Link>
      <ul>
        <li>
          - Projects, programs, community activities - approx number &amp;
          type?
          <ul>
            <li>- offenders</li>
            <li>- aboriginal communities?</li>
            <li>- TAC(?)</li>
          </ul>
        </li>
        <li>
          - How do you want to <b>show</b> arbias&#39; role in the community?
        </li>
        <li>
          - Presenting cultural sensitivity (how do you cater to people of
          different communities)
        </li>
      </ul>
      <h3>People</h3>
      <ul>
        <li>- Lifetime friends - list and profiles?</li>
        <li>- Client stories - case studies</li>
        <ul>
          <li>
            - Like in your&nbsp;
            <a href="https://www.arbias.org.au/content/documents/annual_reports/AnnualReport-2019-Digital-small.pdf">
              Annnual Reports - (page 11)
            </a>
          </li>
          <li>- Featured stories on on the <Link to="/">Home page</Link></li>
        </ul>
        <li>
          - How did you want to present &#39;governance&#39;?
          <ul>
            <li>Profiles of board members (simmilar to client stories)</li>
          </ul>
        </li>
        <li>
          - Closely linked &#39;Executive Mangers&#39;
          <ul>
            <li>- Profile like case stories</li>
            <li>- A paragraph or two about their philosophy etc.</li>
          </ul>
        </li>
        <li>
          - Working at arbias
          <ul>
            <li>- Cultural Activities / rituals</li>
            <li>- Stories from staff member(s)?</li>
            <li>- Links into HR plan</li>
            <li>- Perks?</li>
            <li>- Personal Development?</li>
            <li>- Any other things you could think of?</li>
          </ul>
        </li>
      </ul>
      <h3>Corporate</h3>
      <ul>
        <li>- <Link to="/corporate">Current Page</Link></li>
        <li>- &#39;A word from the CEO&#39;</li>
        <li>- Annual reports</li>
        <li>- HR plan (with statement about open-to-all)</li>
        <li>- Our Philosophy &gt;</li>
        <li>
          - Section on&nbsp;
          <a href="https://www.arbias.org.au/content/documents/strategic_plans/Strategic_Plan_2018.pdf">
            Strategic Plan
          </a>
          also outlooks:
          <ul>
            <li>1 year</li>
            <li>5 year</li>
            <li>10 year</li>
          </ul>
        </li>
        <li>- Condensed summary of most recent annual report</li>
        <li>- Investor resources?</li>
        <li>- Announcements / News / publications</li>
      </ul>
      <h3>About</h3>
      <ul>
        <li>
          - <Link to="/about/">Here&#39;s</Link>&nbsp;
          what I have so far... (theme will be different - closer to the&nbsp;
          <Link to="/about/community/" className="link-primary">
            Community page
          </Link>)
        </li>
        <li>- Executive summary</li>
        <li>- About our clients</li>
        <li>- News / publications</li>
        <li>
          <h5>
            Research
          </h5>
          <ul>
            <li>- How many research papers / studies - number?</li>
          </ul>
        </li>
        <li>
          <h5>
            Training
          </h5>
          <ul>
            <li>
              - Send through the ones which should be publically available
            </li>
            <li>
          - What else do you want to present here?
              <ul>
                <li>- Training room facilities</li>
                <li>- Available training courses?</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          - Careers - working at arbias
        </li>
        <li>
          - Map, Address, contact details etc.
        </li>
        <li>
          - Complaints / feedback
        </li>
        <li>
          - Anything else?
        </li>
      </ul>
      <h3>Our Philosophy</h3>
      <ul>
        <li>
          - How do you want to communicate this?
          <ul>
            <li>- values, vision, mission</li>
            <li>- Testimonials from staff and clients?</li>
            <li>- History</li>
          </ul>
        </li>
        <li>- Person Centred Planning -&gt; Person Directed</li>
      </ul>
      <h3>Information (Information)</h3>
      <ul>
        <li>
          - What Questions did you want to ask?
        </li>
        <li>
          - The targeted information?
          <ul>
            <li>
              - Process? (Diagnosis &gt; Planning &gt; Support)
            </li>
            <li>
              - Information that arbias offers (targeted)
            </li>
            <li>
              - What else? (looking forward booklet)
            </li>
          </ul>
        </li>
      </ul>
      <h3>Content</h3>
      <ul>
        <li>
          - Text: how did you want to handle the generation of it?<br />
          &nbsp;&nbsp;I have the content map
        </li>
        <li>- Images: </li>
      </ul>
      <h3>Privacy Policy...</h3>
      <Link to="/legal">Here</Link>
      <p>Im not a laywer - I have copied it from the old site</p>
      <p>
        I will need to write how the information is used for the feedback
        form as well as the contact form
      </p>
      <p>Also Copyright</p>
      <h3>Covid-19 Update</h3>
      <h3>Publications</h3>
      <Link to="/about/people">Here</Link>
      <ul>
        <li>- Is this the right={true} format?</li>
      </ul>
      <img
        src={InformationDiagram}
        alt="Information diagram" />
      <Link to="/information" className="link-dark">
        Information - mockup
      </Link>
      <h3>Content tags</h3>
      <ContentTag>P05</ContentTag>

      <br /><br /><br /><br /><br /> <br /><br /><br /><br /><br />
      <br /><br /><br /><br /><br /> <br /><br /><br /><br /><br />
      <h3>Architecture</h3>
      <img

        src={Architecture}
        alt="architecture diagram" />
      <br /><br />
      <br /><br /><br /><br /><br /> <br /><br /><br /><br /><br />
    </Page>
  );
};


export default PresentationPage;
